import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"

import SignupForm from "../components/signupform"
import GroupForm from "../components/groupform"

export class Group extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      isDone: true
    }
  }

  handleClick(e){
    this.setState({ isDone: true });
    e.preventDefault();
  }


  render() {

  const bannerStyle = {
      color: 'white',
      backgroundImage: 'url(/group_women.jpg)',
      padding: '16rem 0rem',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center'
  };

  return (

  <Layout>
    <SEO
        title="Groups"
        description="A group chat"
        image="/group_women.jpg"
    />
    <Header/>

    <div class="section" style={bannerStyle}>
      <div class="container">
      </div>
    </div>

    <div class="section groupsignup">
      <div class="container">
        <div class="row">
        <div class="two columns">
        &nbsp;
        </div>
        <div class="eight columns">
          <h3>There&apos;s power in women coming together</h3>

              <p>Where are other women at financially and how do they deal with money?</p>
              <p>Can I learn from women who are doing better than me financially so I can get ahead?</p>
              <p>Could I share my money secrets and tricks with women who aren’t doing as well as me to help them become a little more successful?</p>

              <h4>Join our anonymous peer group on wealth, for women</h4>

              <p className="desc">
              Find a group of women who will challenge and inspire you, spend time with them, and it will change your life.
              </p>

            <h4>It&apos;s free and anonymous</h4>

            <p class="">
            I would love it if you would help me form a peer group of women so we can learn from and help each other get ahead financially.
            </p>

            <p>The aim of the group will be that we collectively accelerate our financial growth and progression towards financial success.</p>

            <p>
            Sign up with your email, and we&apos;ll send you an invitation to join our group shortly.
            </p>

            {
              this.state.isDone &&
              (
                <div>
                <GroupForm />
                </div>
              )

            }

            {
              !this.state.isDone &&
              (

                <div id="mc_embed_signup">
                <div className="clear">
                    <button id="mc_embed_signup" type="submit" name="subscribe" className="button" onClick={(event) => this.handleClick(event)}>I&apos;m interested!</button>
                </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </div>


    <div class="section ">
      <div class="container">
        <div class="row">
          <div class="column">


          </div>
        </div>
      </div>
    </div>




  </Layout>

  )}
}

export default Group
